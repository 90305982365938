import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import routes, { renderRoutes } from './routes';
import { BASENAME } from './config/constant';
import { history } from './utils/history'

const App = () => {
    return (
        <React.Fragment>
            <Router basename={BASENAME} history={history}>{renderRoutes(routes)}</Router>
            {console.log('process.env: ', process.env)}
        </React.Fragment>
    );
};

export default App;
