import React from 'react';
import { ListGroup } from 'react-bootstrap';

const NavVersion = ({ layout, group }) => {
    
    return (
        <React.Fragment>
            <ListGroup.Item as="li" bsPrefix=" " key={group.id} className="nav-item pcoded-menu-caption">
                <label>{group.title}</label>
            </ListGroup.Item>
        </React.Fragment>
    );
};

export default NavVersion;
