const menuItems = {
    items: [

        {
            id: 'group-navigation',
            title: '',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Почеток',
                    type: 'item',
                    url: '',
                    icon: 'feather icon-home'
                },
                {
                    id: 'collapse-administration',
                    title: 'Администрација',
                    type: 'collapse',
                    icon: 'feather icon-sliders',
                    children: [
                        {
                            id: 'button-adm-config',
                            title: 'Параметризација',
                            type: 'item',
                            url: '/app/adm/config'
                        },
                        {
                            id: 'button-adm-funds',
                            title: 'Продукти',
                            type: 'item',
                            url: '/app/adm/funds'
                        },
                        {
                            id: 'button-adm-agents',
                            title: 'Агенти',
                            type: 'item',
                            url: '/app/adm/agents'
                        },
                        {
                            id: 'button-adm-clients',
                            title: 'Договори',
                            type: 'item',
                            url: '/app/adm/contracts'
                        }
                    ]
                },
                {
                    id: 'collapse-import',
                    title: 'Вчитување податоци',
                    type: 'collapse',
                    icon: 'feather icon-upload-cloud',
                    children: [
                        {
                            id: 'button-input-contract',
                            title: 'Договори',
                            type: 'item',
                            url: '/app/input/contracts'
                        },
                        {
                            id: 'button-input-account-statement',
                            title: 'Уплати',
                            type: 'item',
                            url: '/app/input/account'
                        },
                        {
                            id: 'button-input-fund-inits',
                            title: 'Податоци за удели',
                            type: 'item',
                            url: '/app/input/fund/inits'
                        },
                        {
                            id: 'button-input-fund-successfee',
                            title: 'Провизија на успех',
                            type: 'item',
                            url: '/app/input/fund/successfee'
                        }
                    ]
                },
                {
                    id: 'collapse-processing',
                    title: 'Обработки',
                    type: 'collapse',
                    icon: 'feather icon-slack',
                    children: [
                        {
                            id: 'button-pocessing-commissions',
                            title: 'Провизии',
                            type: 'collapse',
                            url: '/app/pocessing/commissions',
                            children: [
                                {   id: 'button-pocessing-commissions-managementcommissions',
                                    title: 'Управувачка Провизија',
                                    type: 'item',
                                    url: '/app/pocessing/commissions/managementcommissions'
                                }, 
                                {   id: 'button-reporting-commissions-entrycommissions',
                                    title: 'Влезна провизија',
                                    type: 'item',
                                    url: '/app/pocessing/commissions/entrycommissions'
                                }]
                        },
                        {
                            id: 'button-pocessing-exceptions',
                            title: 'Отстапки',
                            type: 'item',
                            url: '/app/pocessing/exceptions'
                        },
                        {
                            id: 'button-pocessing-payments',
                            title: 'Исплати',
                            type: 'item',
                            url: '/app/pocessing/payments'
                        }
                    ]
                },
                {
                    id: 'collapse-reports',
                    title: 'Извештаи',
                    type: 'collapse',
                    icon: 'feather icon-layers',
                    children: [
                        {
                            id: 'button-reporting-analitics-agent',
                            title: 'Агент',
                            type: 'item',
                            url: '/app/reporting/agent'
                        },
                        {
                            id: 'button-reporting-analitics-client',
                            title: 'Клиент',
                            type: 'item',
                            url: '/app/reporting/client'
                        },
                        {
                            id: 'button-reporting-commissions',
                            title: 'Провизии',
                            type: 'collapse',
                            children: [
                                {   id: 'button-reporting-commissions-managementCommissions',
                                    title: 'Управувачка Провизија',
                                    type: 'item',
                                    url: '/app/reporting/commissions/managementcommissions'
                                }, 
                                {   id: 'button-reporting-commissions-entryCommissions',
                                    title: 'Влезна провизија',
                                    type: 'item',
                                    url: '/app/reporting/commissions/entrycommissions'
                                }
                            ]
                            
                        },
                        {
                            id: 'button-reporting-exceptions',
                            title: 'Отстапки',
                            type: 'item',
                            url: '/app/reporting/exceptions'
                        }
                    ]
                },
                //{
                //    id: 'support',
                //    title: 'Побарај помош',
                //    type: 'item',
                //    icon: 'feather icon-help-circle',
                //    classes: 'nav-item',
                //    url: '/app/support/call'
                //}
            ]
        },
        {
            id: 'group-version',
            title: 'MLM Alpha version 1.7',
            type: 'version',
            icon: 'icon-navigation',
        },

    ]
};

export default menuItems;
