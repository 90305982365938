import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';

import { BASE_URL } from './config/constant';

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<Loader />}>
        <Switch>
            <Route
                key={-1}
                path={"/api/*"}
                exact={false}
            />
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signin',
        component: lazy(() => import('./views/auth/signin/SignIn1'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signup',
        component: lazy(() => import('./views/auth/signup/SignUp1'))
    },
    {
        exact: true,
        path: '/auth/signin-2',
        component: lazy(() => import('./views/auth/signin/SignIn2'))
    },
    {
        exact: true,
        path: '/auth/signup-2',
        component: lazy(() => import('./views/auth/signup/SignUp2'))
    },
    {
        path: '*',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: false,
                path: '/app/underconstraction',
                component: lazy(() => import('./views/underconstraction'))
            },
            {
                exact: true,
                path: '/app/dashboard/default',
                component: lazy(() => import('./views/dashboard/DashDefault'))
            },

            {
                exact: true,
                path: '/app/adm/config',
                component: lazy(() => import('./views/underconstraction'))
            },
            {
                exact: true,
                path: '/app/adm/funds',
                component: lazy(() => import('./views/funds'))
            },
            {
                exact: true,
                path: '/app/adm/agents',
                component: lazy(() => import('./views/agents'))
            },
            {
                exact: true,
                path: '/app/adm/contracts',
                component: lazy(() => import('./views/contracts'))
            },
            {
                exact: true,
                path: '/app/adm/contracts/new',
                component: lazy(() => import('./views/contracts/new'))
            },
            {
                exact: true,
                path: '/app/adm/contracts/edit',
                component: lazy(() => import('./views/contracts/edit'))
            },

            {
                exact: true,
                path: '/app/input/contracts',
                component: lazy(() => import('./views/uploads/contracts'))
            },
            {
                exact: true,
                path: '/app/input/account',
                component: lazy(() => import('./views/uploads/account'))
            },
            {
                exact: true,
                path: '/app/input/fund/inits',
                component: lazy(() => import('./views/underconstraction'))
            },
            {
                exact: true,
                path: '/app/input/fund/successfee',
                component: lazy(() => import('./views/underconstraction'))
            },
            
            {
                exact: true,
                path: '/app/pocessing/commissions/entrycommissions',
                component: lazy(() => import('./views/pocessing/commissions/entrycommissions'))
            },
            {
                exact: true,
                path: '/app/pocessing/commissions/managementcommissions',
                component: lazy(() => import('./views/pocessing/commissions/managementcommissions'))
            },
            {
                exact: true,
                path: '/app/pocessing/exceptions',
                component: lazy(() => import('./views/pocessing/exceptions'))
            },
            {
                exact: true,
                path: '/app/pocessing/payments',
                component: lazy(() => import('./views/pocessing/payments'))
            },

            {
                exact: true,
                path: '/app/reporting/agent/preview',
                component: lazy(() => import('./views/reporting/agent/AgentReport'))
            }, {
                exact: true,
                path: '/app/reporting/agent',
                component: lazy(() => import('./views/reporting/agent'))
            },
            {
                exact: true,
                path: '/app/reporting/client',
                component: lazy(() => import('./views/reporting/client'))
            },
            {
                exact: true,
                path: '/app/reporting/commissions/managementcommissions/',
                component: lazy(() => import('./views/reporting/commissions/managementcommissions'))
            },
            {
                exact: true,
                path: '/app/reporting/commissions/entrycommissions/',
                component: lazy(() => import('./views/reporting/commissions/entrycommissions'))
            },
            {
                exact: true,
                path: '/app/reporting/exceptions',
                component: lazy(() => import('./views/reporting/exceptions'))
            },

            {
                exact: true,
                path: '/app/support/call',
                component: lazy(() => import('./views/underconstraction'))
            },


            {
                exact: true,
                path: '/basic/button',
                component: lazy(() => import('./views/ui-elements/basic/BasicButton'))
            },
            {
                exact: true,
                path: '/basic/badges',
                component: lazy(() => import('./views/ui-elements/basic/BasicBadges'))
            },
            {
                exact: true,
                path: '/basic/breadcrumb',
                component: lazy(() => import('./views/ui-elements/basic/BasicBreadcrumb'))
            },
            {
                exact: true,
                path: '/basic/collapse',
                component: lazy(() => import('./views/ui-elements/basic/BasicCollapse'))
            },
            {
                exact: true,
                path: '/basic/tabs-pills',
                component: lazy(() => import('./views/ui-elements/basic/BasicTabsPills'))
            },
            {
                exact: true,
                path: '/basic/typography',
                component: lazy(() => import('./views/ui-elements/basic/BasicTypography'))
            },

            {
                exact: true,
                path: '/forms/form-basic',
                component: lazy(() => import('./views/forms/FormsElements'))
            },
            {
                exact: true,
                path: '/tables/bootstrap',
                component: lazy(() => import('./views/tables/BootstrapTable'))
            },

            {
                exact: true,
                path: '/charts/nvd3',
                component: lazy(() => import('./views/charts/nvd3-chart'))
            },
            {
                exact: true,
                path: '/maps/google-map',
                component: lazy(() => import('./views/maps/GoogleMaps'))
            },

            {
                exact: true,
                path: '/sample-page',
                component: lazy(() => import('./views/extra/SamplePage'))
            },
            {
                path: '*',
                exact: true,
                component: () => <Redirect to={BASE_URL} />
            }
        ]
    }
];

export default routes;
