// import process from "process";

export const OPERATION_TYPE = "PROD"; //process.env.REACT_APP_OPERATION_TYPE; //"DEV"; // PROD , DEV, TEST
export const BASENAME = ''; // don't add '/' at end off BASENAME
export const BASE_URL = '/app/dashboard/default';
export const BASE_TITLE = ' | MLM Alpha ';
export const API_SERVER_DEV = 'https://localhost:44366/api/';
export const API_SERVER_TEST = 'https://localhost:44366/api/';
export const API_SERVER_PROD = 'https://mlm-api.graviton.mk/api/';
export const API_SERVER = (OPERATION_TYPE === "PROD"
    ? API_SERVER_PROD
    : (OPERATION_TYPE === "TEST"
        ? API_SERVER_TEST
        : API_SERVER_DEV));
export const API_SERVER_PHOTO_DEV = 'https://localhost:44366/Photos/';
export const API_SERVER_PHOTO_TEST = 'https://localhost:44366/Photos/';
export const API_SERVER_PHOTO_PROD = 'https://mlm-api.graviton.mk/Photos/';
export const API_SERVER_PHOTO = (OPERATION_TYPE === "PROD"
    ? API_SERVER_PHOTO_PROD
    : (OPERATION_TYPE === "TEST"
        ? API_SERVER_PHOTO_TEST
        : API_SERVER_PHOTO_DEV));
export const DEFAULT_LIST_PAGESIZE = 10;
export const TIMEOUT_ALERT = 3000;
export const TIMEOUT_REDIRECT = 3000;

export const CONFIG = {
    layout: 'vertical', // disable on free version
    subLayout: '', // disable on free version
    collapseMenu: false, // mini-menu
    layoutType: 'menu-dark', // disable on free version
    navIconColor: false, // disable on free version
    headerBackColor: 'header-default', // disable on free version
    navBackColor: 'navbar-default', // disable on free version
    navBrandColor: 'brand-default', // disable on free version
    navBackImage: false, // disable on free version
    rtlLayout: false, // disable on free version
    navFixedLayout: true, // disable on free version
    headerFixedLayout: false, // disable on free version
    boxLayout: false, // disable on free version
    navDropdownIcon: 'style1', // disable on free version
    navListIcon: 'style1', // disable on free version
    navActiveListColor: 'active-default', // disable on free version
    navListTitleColor: 'title-default', // disable on free version
    navListTitleHide: false, // disable on free version
    configBlock: true, // disable on free version
    layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // disable on free version
    layout6BackSize: '' // disable on free version
};
